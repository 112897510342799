import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import 'tailwindcss/tailwind.css'
import '../styles/global.css'
import { ReactElement, ReactNode } from 'react'
import { Toaster } from 'react-hot-toast'
import { RecoilRoot } from 'recoil'

import usePageView from '@/hooks/usePageView'

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    usePageView()
    const getLayout = Component.getLayout ?? ((page) => page)

    return (
        <RecoilRoot>
            {getLayout(
                <>
                    <Head>
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
                        />
                        <meta
                            name="facebook-domain-verification"
                            content="5fwngrfnnssyjpysnplp8b0xxumln3"
                        />
                        {process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' && (
                            <meta name="robots" content="noindex" />
                        )}
                    </Head>
                    <Toaster />
                    <Component {...pageProps} className={'bg-gray-50'} />
                </>
            )}
        </RecoilRoot>
    )
}

export default App
